<template>
  <div>
    <b-form ref="textform" @submit="onSubmit" v-if="show">
      <b-form-group id="email">
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          :value="emailUser"
          :placeholder="emailUser"
          disabled
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="name">
        <b-form-input
          id="name"
          v-model="form.name"
          :value="nameUser"
          :placeholder="nameUser"
          disabled
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="country">
        <b-form-input
          id="country"
          v-model="form.country"
          placeholder="Country"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="postcode">
        <b-form-input
          id="postcode"
          v-model="form.postcode"
          placeholder="Postcode"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="address1">
        <b-form-input
          id="address1"
          v-model="form.address1"
          placeholder="Address 1"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="address2">
        <b-form-input
          id="address2"
          v-model="form.address2"
          placeholder="Address 2"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="city">
        <b-form-input
          id="city"
          v-model="form.city"
          placeholder="City"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="phonenumber">
        <b-form-input
          id="phonenumber"
          v-model="form.phonenumber"
          placeholder="Contact Phone Number"
          required
        ></b-form-input>
      </b-form-group>
      <br />
      <b-form-group
        class=""
        label="Select T-Shirt Size:"
        v-slot="{ ariaDescribedby }"
        required
      >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="XS"
          >XS</b-form-radio
        >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="S"
          >S</b-form-radio
        >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="M"
          >M</b-form-radio
        >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="L"
          >L</b-form-radio
        >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="XL"
          >XL</b-form-radio
        >
        <b-form-radio
          v-model="form.tshirt_size"
          :aria-describedby="ariaDescribedby"
          name="tshirt_size"
          value="XXL"
          >XXL</b-form-radio
        >
      </b-form-group>
      <b-form-group
        label="When do you want to receive your box?"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio
          v-model="form.receive"
          :aria-describedby="ariaDescribedby"
          name="receiveDate"
          value="Second half of August"
          >Second half of August
        </b-form-radio>
        <b-form-radio
          v-model="form.receive"
          :aria-describedby="ariaDescribedby"
          name="receiveDate"
          value="First half of September"
          >First half of September
        </b-form-radio>
        <b-form-radio
          v-model="form.receive"
          :aria-describedby="ariaDescribedby"
          name="receiveDate"
          value="Second half of September"
          >Second half of September
        </b-form-radio>
        <b-form-radio
          v-model="form.receive"
          :aria-describedby="ariaDescribedby"
          name="receiveDate"
          value="October"
          >October
        </b-form-radio>
      </b-form-group>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialog = false"
              id="botao"
              type="submit"
              variant="primary"
              v-bind:disabled="!disableButton"
              v-bind="attrs"
              v-on="on"
            >
              Submit
            </v-btn>
          </template>

          <v-card class="awesome">
            <v-card-title class="text-center text-h5 white lighten-2">
              Awesome!
            </v-card-title>

            <v-card-text>
              We have received your credentials and preferences! Wait for the
              box from us! Thank you!</v-card-text
            >

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="white" text @click="reset()"> Got It! </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "FormGiftStore",
  data() {
    return {
      form: {
        email: "",
        name: "",
        country: "",
        postcode: "",
        address1: "",
        address2: "",
        city: "",
        phonenumber: "",
        product: "empty",
        tshirt_size: "",
        receive: "",
      },
      product: ["Product 1", "Product 2", "Product 3"],
      show: true,
      dialog: false,
    };
  },
  computed: {
    nameUser() {
      return this.$store.state.userLogin.name;
    },
    emailUser() {
      return this.$store.state.userLogin.email;
    },
    disableButton: function () {
      return (
        this.form.country.length > 0 &&
        this.form.postcode.length > 0 &&
        this.form.address1.length > 0 &&
        this.form.city.length > 0 &&
        this.form.phonenumber.length > 0 &&
        this.form.tshirt_size.length > 0 &&
        this.form.receive.length > 0
      );
    },
  },
  created() {
    this.getUser();
    console.log(this.form.tshirt_size);
  },
  methods: {
    getUser() {
      this.form.name = this.$store.state.userLogin.name;
      this.form.email = this.$store.state.userLogin.email;
    },
    onSubmit(event) {
      var formdata = new FormData();
      formdata.append("email", this.form.email);
      formdata.append("name", this.form.name);
      formdata.append("country", this.form.country);
      formdata.append("postcode", this.form.postcode);
      formdata.append("address1", this.form.address1);
      formdata.append("address2", this.form.address2);
      formdata.append("city", this.form.city);
      formdata.append("phonenumber", this.form.phonenumber);
      formdata.append("product", this.form.product);
      formdata.append("tshirt_size", this.form.tshirt_size);
      formdata.append("receive", this.form.receive);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(
        "https://tp-meeting-guild.bydservices.pt/api/public/api/order/create",
        requestOptions
      )
        .then((response) => response.text())
        // .then((result) => alert(result))
        .catch((error) => alert("error", error));
      event.preventDefault();
    },
    reset() {
      this.$refs.textform.reset();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.v-card__title.text-center.text-h5.white.lighten-2 {
  flex-direction: column;
  color: #7e08a8;
}

.v-btn.v-btn--text.theme--light.v-size--default.white--text {
  background-color: #ff5c00;
}

.v-btn.v-btn--text.theme--light.v-size--default.red--text {
  color: white;
}

.v-card__actions {
  flex-direction: column;
}

.btn-primary,
#botao,
.btn {
  font-family: "AlegreyaSans-BlackItalic";
  color: #fff !important;
  background-color: #ff5c00;
  border: white;
  border-radius: 0% !important;
  width: 100%;
  height: 5vh;
  font-size: 20px;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  padding: 0 !important;
}

.awesome {
  flex-direction: column;
}

form input,
form select {
  font-family: "WorkSans-Regular" !important;
  border-radius: 0 !important;
  line-height: 1.5;
  width: 500px;
  height: 30px;
}

@media only screen and (max-width: 800px) {
  form {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 0px !important;
  }

  form input,
  form select {
    width: 100%;
    margin-right: 0px !important;
  }
}
</style>
