import axios from "axios";

const instance = axios.create({
    baseURL: 'https://tp-meeting-guild.bydservices.pt/api/public/api/',
    // baseURL: 'https://plataforma.ducafe.pt/api/public/api',
    headers: {
        "content-type": "application/json",
    },
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
});
 
export default {
    async signIn(data) {
        try {
            const res = await instance.post('/login', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async getAllUsers() {
        try {
            const res = await instance.post('getUserData');
            const users = Object.values(res.data.utilizadores); 
            return users;
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}