<template>
  <section>
    <Navbar />
    <Slides />
    <Navigation />
    <NextEvent />
    <MembershipBox />
  </section>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Slides from "../components/Slides.vue";
import Navigation from "../components/Navigation.vue";
import NextEvent from "../components/NextEvent.vue";
import MembershipBox from "./MembershipBox.vue";
export default {
  name: "Home",
  props: ["layoutChange"],
  components: {
    Navbar,
    Slides,
    Navigation,
    NextEvent,
    MembershipBox,
  },
  async created() {
    this.$props.layoutChange(this.$route.path);
    this.$router.push("/next-event");
  },
};
</script>

<style scoped>
</style>