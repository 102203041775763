<template>
  <div>
    <b-navbar
      id="mobileNavbar"
      class="navigation displayMenuMobile"
      type="dark"
      variant="dark"
    >
      <router-link to="#"><span @click="showMenu">MENU</span></router-link>
    </b-navbar>
    <b-navbar
      id="desktopNavbar"
      class="navigation displayMenuDesktop"
      type="dark"
      variant="dark"
    >
      <router-link to="next-event"
        ><span @click="hideMenu">NEXT EVENT</span></router-link
      >
      <router-link to="past-events"
        ><span @click="hideMenu">PAST EVENTS</span></router-link
      >
      <router-link to="guild-members"
        ><span @click="hideMenu">GUILD MEMBERS</span></router-link
      >
      <router-link to="mission-vision"
        ><span @click="hideMenu">MISSION AND VISION</span></router-link
      >
      <router-link to="membership-box"
        ><span @click="hideMenu">MEMBERSHIP BOX</span></router-link
      >
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      showMenuMobile: false,
    };
  },
  methods: {
    hideMenu() {
      document
        .getElementById("desktopNavbar")
        .setAttribute("style", "display:  !important");
    },
    showMenu() {
      if (this.showMenuMobile == false) {
        this.showMenuMobile = true;
        document
          .getElementById("desktopNavbar")
          .setAttribute("style", "display: flex !important");
        console.log(this.showMenuMobile, "mostrar");
      } else {
        this.showMenuMobile = false;
        document
          .getElementById("desktopNavbar")
          .setAttribute("style", "display: none !important");
      }
      console.log(this.showMenuMobile, "mostrar");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);

.navigation {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25) !important;
  font-family: "AlegreyaSans-BlackItalic" !important;
}

a {
  font-size: 26px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  justify-content: space-between;
  text-decoration: none !important;
  background: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
}

a.router-link-exact-active.router-link-active {
  text-decoration: none;
}

.router-link-exact-active {
  border-bottom: 2px solid #7e08a8;
  background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar.navbar-dark.bg-dark {
  background-color: white !important;
}

.navbar {
  padding: 25px 6vw 25px 6vw !important;
  // padding: 25px 15vw 25px 15vw !important;
}

#mobileNavbar {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .navbar {
    padding: 25px 15vw 25px 15vw !important;
  }

  .navbar.navigation.navbar-dark.bg-dark.navbar-expand {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  #mobileNavbar {
    display: flex;
  }

  #desktopNavbar {
    display: none;
  }
}
</style>

