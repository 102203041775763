import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

import api from "@/api/index"

Vue.use(Vuex);

const initialState = () => {
  return {
    userLogin: {},
    userData: {},
  }
};

export default new Vuex.Store({
  state: initialState(),
  // plugins: [createPersistedState()],
  mutations: {
    UPDATE_STATE(state,payload) {
      state[payload.item] = payload.data;
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    }
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async signIn({ commit, dispatch },data) {
      let res = await api.signIn(data);
      await commit("UPDATE_STATE", {
        item: "userLogin",
        data: res
      });
      await dispatch("getUserData");
    },
    async getUserData({ commit, state }){
      let users = await api.getAllUsers();
      let id = state.userLogin.userID;
      let user = users.find((user) => user.id == id);
      commit("UPDATE_STATE", {
        item: "userData",
        data: user
      });
    },
  },
});
