<template>
  <div class="slider">
    <!-- DESKTOP SLIDER -->
    <b-carousel
      class="carousel-desktop"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
    >
      <!-- Slide 1 -->
      <b-carousel-slide
        img-src="https://cxgamesguild.com/assets/GAMESCXGUILD_BANNER_2200x700_02.png"
      >
        <br />
        <h1>
          WELCOME, <br />
          GUILD MEMBERS!
        </h1>
        <br />
        <p id="p1">
          This is the platform for you. Find out more about Games CX Guild
          community, catch up with the latest updates and get the insights.
        </p>
      </b-carousel-slide>
      <!-- Slide 2 -->
      <b-carousel-slide img-src="https://imgur.com/W1OPIEQ.png">
        <br />
        <h1 class="banner2">
          GUILD 2021 <br />
          KEY TAKEAWAYS
        </h1>
        <p class="banner2">
          This time around, we have covered self-service tools and solutions
          that help improve the player experience.
        </p>
        <br />
        <v-btn id="botao">
          <a
            target="_blank"
            href="https://www.cxgamesguild.com/docs/2021/Games_CX_GUILD_White_Paper_Game_Changers_Trends_2021.pdf"
          >
            DOWNLOAD THE PAPER
          </a>
        </v-btn>
      </b-carousel-slide>
      <!-- Slide 3 -->
      <b-carousel-slide img-src="https://imgur.com/qiVlKib.png">
        <br />
        <h1 class="banner2">
          GET YOUR GUILD <br />
          MEMBERSHIP BOX!
        </h1>
        <p class="banner2">
          We've got something for you! Visit the Membership Box page to sign in
          for your special package delivery.
        </p>
        <br />
        <v-btn class="send-box" id="botao"
          ><router-link to="membership-box" v-scroll-to="'#scroll-desktop'"
            >SEND ME THE BOX</router-link
          >
        </v-btn>
        <!-- <button v-scroll-to="{ el: '#oiii' }">
          Scroll to 200px below #element
        </button> -->
      </b-carousel-slide>
      <!-- Slide 4 -->
      <b-carousel-slide img-src="https://i.imgur.com/EYqL7yd.png">
        <br />
        <h1 class="banner2">
          FIND YOUR <br />
          PEER!
        </h1>
        <p class="banner2">
          Can't wait to reconnect with Guild members again? Look for your peers
          in our membership list and find their contacts!
        </p>
        <br />
        <v-btn id="botao"
          ><router-link to="guild-members" v-scroll-to="'#scroll-desktop'"
            >SEE GUILD MEMBERS</router-link
          ></v-btn
        >
      </b-carousel-slide>
      <!-- Slide 5 -->
      <b-carousel-slide img-src="https://imgur.com/T5X69N0.png">
        <br />
        <h1 class="banner2">
          HOW TO STAY <br />
          IN THE GAME?
        </h1>
        <p class="banner2">
          Discover resources and technology priorities, which help create
          services the players expect, with Pascal Debroek, Founder and Content
          Creator at The PX Hub.
        </p>
        <br />
        <v-btn id="botao-webinar"
          ><router-link to="next-event" v-scroll-to="'#scroll-desktop'"
            >SIGN IN FOR THE WEBINAR
          </router-link></v-btn
        >
      </b-carousel-slide>
    </b-carousel>
    <a id="scroll-desktop" href=""></a>
    <!-- MOBILE SLIDER -->
    <b-carousel
      class="carousel-mobile"
      v-model="slide"
      :interval="4000"
      indicators
      background="#fff"
      img-width="2048"
      img-height="4096"
      style="text-shadow: 1px 1px 2px #333"
    >
      <!-- Slide 1 -->
      <b-carousel-slide
        img-src="https://cxgamesguild.com/assets/01_GAMESCXGUILD_BANNER_375x350-txt.png"
      >
        <br />
        <h1>
          WELCOME, <br />
          GUILD MEMBERS!
        </h1>
        <br />
        <p id="p1">
          This is the platform for you. Find out more about Games CX Guild
          community, catch up with the latest updates and get the insights.
        </p>
      </b-carousel-slide>
      <!-- Slide 2 -->
      <b-carousel-slide img-src="https://i.imgur.com/degCLam.png">
        <br />
        <h1 class="banner2">
          GUILD 2021 <br />
          KEY TAKEAWAYS
        </h1>
        <p class="banner2">
          This time around, we have covered self-service tools and solutions
          that help improve the player experience.
        </p>
        <br />
        <v-btn id="botao">
          <a
            target="_blank"
            href="https://www.cxgamesguild.com/docs/2021/Games_CX_GUILD_White_Paper_Game_Changers_Trends_2021.pdf"
          >
            DOWNLOAD THE PAPER
          </a>
        </v-btn>
      </b-carousel-slide>
      <!-- Slide 3 -->
      <b-carousel-slide img-src="https://i.imgur.com/fk7yYnb.png">
        <br />
        <h1 class="banner2">
          GET YOUR GUILD <br />
          MEMBERSHIP BOX!
        </h1>
        <p class="banner2-box-mobile">
          We've got something for you! Visit the Membership Box page to sign in
          for your special package delivery.
        </p>
        <br />
        <v-btn class="send-box" id="botao"
          ><router-link to="membership-box" v-scroll-to="'#scroll-mobile'"
            >SEND ME THE BOX</router-link
          ></v-btn
        >
      </b-carousel-slide>
      <!-- Slide 4 -->
      <b-carousel-slide img-src="https://i.imgur.com/9pgcIgn.png">
        <br />
        <h1 class="banner2">
          FIND YOUR <br />
          PEER!
        </h1>
        <p class="banner2">
          Can't wait to reconnect with Guild members again? Look for your peers
          in our membership list and find their contacts!
        </p>
        <br />
        <v-btn id="botao"
          ><router-link to="guild-members" v-scroll-to="'#scroll-mobile'"
            >SEE GUILD MEMBERS</router-link
          ></v-btn
        >
      </b-carousel-slide>
      <!-- Slide 5 -->
      <b-carousel-slide img-src="https://i.imgur.com/EBqihYL.png">
        <br />
        <h1 class="banner2">
          HOW TO STAY <br />
          IN THE GAME?
        </h1>
        <p class="banner2-webinar">
          Discover resources and technology priorities, which help create
          services the players expect, with Pascal Debroek, Founder and Content
          Creator at The PX Hub.
        </p>
        <br />
        <v-btn id="botao"
          ><router-link to="next-event" v-scroll-to="'#scroll-mobile'"
            >SIGN IN FOR THE WEBINAR
          </router-link></v-btn
        >
      </b-carousel-slide>
    </b-carousel>
    <a id="scroll-mobile" href=""></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
    };
  },
  computed: {
    nameUser() {
      return this.$store.state.userLogin.name;
    },
    imagemUser() {
      return this.$store.state.userLogin.imagem;
    },
  },
};
</script>


<style scoped>
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);

/* >= 24' Screen */
div {
  z-index: -0 !important;
  height: 60vh;
}

.img-fluid {
  padding-bottom: 50px;
}

#logo {
  position: absolute;
  z-index: 100;
  top: 50px;
  left: 10px;
  height: 100px;
  display: none;
}

.carousel-mobile {
  visibility: hidden;
}

h1 {
  font-family: "AlegreyaSans-BlackItalic" !important;
  position: absolute;
  z-index: 100;
  top: 160px;
  left: 10px;
  width: 100%;
  font-size: 80px;
  text-align: left !important;
}

h2 {
  top: 300px;
  font-family: "AlegreyaSans-BlackItalic" !important;
  position: absolute;
  z-index: 100;
  color: #f2994a;
  left: 10px;
  width: 100%;
  font-size: 80px !important;
  text-align: left !important;
}

p {
  font-family: "WorkSans-Regular" !important;
  left: 10px;
  position: absolute;
  width: 50%;
  top: 400px;
  text-align: left;
  font-size: 20px;
}

a {
  color: #fff !important;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  font-size: 26px;
}

h1.banner2 {
  top: 150px;
}

h2.banner2 {
  top: 250px;
}

p.banner2 {
  top: 340px;
}

button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  position: absolute;
  color: #ffffff;
  background-color: #ff5c00;
  border-radius: 0% !important;
  top: 420px;
  left: 10px;
  height: 6vh;
  width: 20vw;
  font-family: "AlegreyaSans-BlackItalic" !important;
  font-size: 20px;
}

.send-box {
  background-color: black !important;
  border: none !important;
}

#botao-webinar {
  top: 440px;
  border: none !important;
  width: inherit;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}

@media only screen and (max-width: 1780px) {
  div {
    height: 55vh;
  }

  h1 {
    top: 140px;
    font-size: 60px;
  }

  h2 {
    top: 250px;
    font-size: 30px !important;
  }

  v-btn {
    top: 200px;
  }

  #p1 {
    top: 300px;
    font-size: 16px;
  }

  h1.banner2 {
    top: 80px;
    font-size: 60px;
  }

  p.banner2 {
    top: 240px;
    font-size: 16px;
  }

  #p2 {
    top: 300px;
    font-size: 14px;
  }

  #botao {
    top: 320px;
    height: 5vh;
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #botao-webinar {
    top: 340px;
    height: 5vh;
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #logo {
    height: 80px;
  }
}

@media only screen and (max-width: 1360px) {
  div {
    min-height: 53vh;
  }

  h1 {
    top: 120px;
    font-size: 55px;
  }

  #p1 {
    top: 260px;
    font-size: 16px;
  }

  h2 {
    top: 250px;
    font-size: 30px !important;
  }

  h1.banner2 {
    top: 120px;
    font-size: 60px;
  }

  p.banner2 {
    top: 260px;
    font-size: 16px;
  }

  h2.banner2 {
    top: 220px;
    font-size: 30px !important;
  }

  v-btn {
    top: 200px;
  }

  #botao {
    top: 320px;
    height: 5vh;
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #botao-webinar {
    top: 360px;
    height: 5vh;
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #logo {
    height: 80px;
  }
}

@media only screen and (max-width: 1180px) {
  div {
    height: 35vh;
  }

  h1 {
    top: 50px;
    font-size: 50px;
  }

  #p1 {
    top: 200px;
    font-size: 14px;
  }

  h1.banner2 {
    top: 80px;
    font-size: 60px;
  }

  p.banner2 {
    top: 210px;
    font-size: 16px;
  }

  #logo {
    height: 60px;
  }

  .send-box p {
    font-size: 8px !important;
    border: none !important;
  }

  #botao {
    top: 290px;
    /* height: 3vh; */
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #botao-webinar {
    top: 340px;
    /* height: 3vh; */
    font-size: 14px;
    width: inherit;
    border: none !important;
  }
}

@media only screen and (max-width: 1040px) {
  div {
    height: 30vh;
  }

  h1 {
    top: 60px;
    font-size: 50px;
  }
  #p1 {
    top: 200px;
    font-size: 14px;
    /* width: 100%; */
  }

  h1.banner2 {
    top: 60px;
    font-size: 60px;
  }
  p.banner2 {
    top: 200px;
    font-size: 16px;
  }

  #logo {
    height: 60px;
  }

  #botao {
    top: 300px;
    /* height: 3vh; */
    font-size: 14px;
    width: inherit;
    border: none !important;
  }

  #botao-webinar {
    top: 340px;
    /* height: 3vh; */
    font-size: 14px;
    width: inherit;
    border: none !important;
  }
}

/* Phone */
@media only screen and (max-width: 900px) {
  div {
    height: 50vh;
    min-height: 50vh;
  }

  .carousel-mobile {
    visibility: visible !important;
  }

  .carousel-desktop {
    display: none !important;
  }

  a {
    font-size: 14px !important;
  }

  p {
    width: 100%;
  }

  h1 {
    top: 60px;
    width: 100%;
    font-size: 32px;
  }

  #p1 {
    top: 180px;
    font-size: 14px !important;
    width: 95%;
  }

  h1.banner2 {
    top: 50px;
    width: 100%;
    font-size: 30px;
  }

  p.banner2 {
    top: 43%;
    font-size: 12px !important;
    width: 95%;
  }

  p.banner2-box-mobile {
    top: 50%;
    font-size: 10px !important;
    width: 75%;
  }

  p.banner2-webinar {
    top: 40% !important;
    font-size: 12px !important;
  }

  #botao {
    top: 75%;
    border: none !important;

    /* height: 4vh; */
    /* width: 100% */
  }
}
</style>
