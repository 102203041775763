<template>
  <v-app-bar app color="rgba(51, 51, 51)" height="40">
    <div class="d-flex row navbar">
      <div id="welcome" class="d-flex align-center"></div>
      <div class="d-flex align-right pt-2">
        <div id="welcome" class="d-flex align-center">
          <p id="user">
            Welcome back, {{ nameUser.split(" ")[0] }}!
            <img
              :src="'https://cxgamesguild.com/members/' + imagemUser"
              alt=""
            />
          </p>
        </div>
        <v-menu offset-y class="ml-md-5 ml-xs-0">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="navbar-user d-flex align-center">
              <p id="logout" @click="logout()">Logout</p>
            </div>
          </template>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  components: {},

  data: () => ({
    items: [{ title: "Logout" }],
  }),
  methods: {
    logout() {
      this.$router.push("/login");
      location.reload();
    },
  },
  computed: {
    nameUser() {
      return this.$store.state.userLogin.name;
    },
    imagemUser() {
      return this.$store.state.userLogin.imagem;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);

div {
  // position: fixed!important;
  z-index: 100 !important;
}

p,
#welcome {
  color: white;
  // display: flex;
  // padding-right: 20vw
}

.navbar {
  padding-top: 10px;
}
#user {
  padding-top: 10px;
}

#welcome p {
  text-transform: capitalize;
}

#welcome img {
  max-height: 25px;
  border-radius: 50%;
}

#logout {
  margin-left: 3vw;
  margin-right: 2vw;
  padding-top: 10px;
  cursor: pointer;
}

.d-flex.align-right.pt-2 {
  padding-bottom: 10px;
}

p.mr-2 {
  padding-left: 0.2em;
}

@media only screen and (max-width: 800px) {
  .navbar {
    padding: 20px 1vw 20px 1vw !important;
    padding-right: 20%;
  }

  img {
    margin-left: 10px;
  }

  .d-flex.row.navbar,
  p,
  .d-flex.align-right.pt-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-start;
  }
}
</style>