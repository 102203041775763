<template>
  <LayoutLogin :login="true" :loginForm="loginForm" />
</template>

<script>
//Layout:
import LayoutLogin from "../template/LayoutLogin.vue";

export default {
  props: ["layoutChange"],
  components: {
    LayoutLogin,
  },
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
      console.log(typeof name, "name");
      console.log(typeof email, "email");
      console.log(typeof namesRules, "nameRules");
      console.log(typeof valid, "boolean");
      console.log(typeof select, "select");
      console.log(typeof items, "items");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async loginForm(email, password) {
      const apiBody = {
        email,
        password,
      };

      await this.$store.dispatch("signIn", apiBody);
      this.$router.push("/");
    },
  },
  created() {},
};
</script>


<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
</style>
