import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store/index";

import Login from "../components/Login.vue";
import Home from "../components/Home.vue";
import NextEvent from "../components/NextEvent.vue";
import MissionVision from "../components/MissionVision.vue";
import GuildMembers from "../components/GuildMembers.vue";
import PastEvents from "../components/PastEvents.vue";
import MembershipBox from "../components/MembershipBox.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/next-event",
    name: "NextEvent",
    component: NextEvent,
  },
  {
    path: "/mission-vision",
    name: "MissionVision",
    component: MissionVision,
  },
  {
    path: "/guild-members",
    name: "GuildMembers",
    component: GuildMembers,
  },
  {
    path: "/past-events",
    name: "PastEvents",
    component: PastEvents,
  },
  {
    path: "/membership-box",
    name: "MembershipBox",
    component: MembershipBox,
  },  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let name = store.state.userLogin.name;
  // let avatar = store.state.avatarUser.avatar;

  if(to.name != 'Login' && to.name != 'ChangePassword' && name == undefined) next({ name: "Login" })
  else if((to.name === 'Login' || to.name === 'ChangePassword') && (name != null)) next({ name: "Home" });
  else next();
});

export default router;
