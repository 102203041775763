<template>
  <div class="relative">
    <section class="hackathon second">
      <div class="hackathon-slider flex w-20 h-40">
        <div class="slide flex w-20 h-30">
          <div class="w-20 flex ai-c" id="first-session">
            <div :class="{ accordion__trigger_active: visible }">
              <h2>GAMES CX GUILD</h2>
              <h3>THE COUNCIL</h3>
              <h4>DECEMBER 3, 2020</h4>
              <p class="mb-5 mt-5">
                Our first time going virtual, this gathering was remarkable,
                what with brand new challenges and best practices shared by the
                speakers and questions raised by the attendees as a result of
                the crazy year we all had. However, the Guild members had one
                more thing to deal with at this convention — a looming threat
                within the community.
              </p>
              <a
                id="linkEvent"
                href="https://2020.cxgamesguild.com/"
                target="_blank"
                >VISIT THE EVENT PAGE</a
              >
            </div>
            <div class="w-30">
              <video
                autoplay
                muted
                loop
                controls
                class="bg-video"
                width="520"
                height="auto"
              >
                <source
                  src="https://cxgamesguild.com/assets/Guild_Video_Resumo_V03_compressed.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hackathon second" style="min-height: 20px !important">
      <div class="hackathon-slider flex w-100">
        <div class="slide flex w-100">
          <div class="flex ai-c">
            <div class="w-100" id="second-session">
              <h3>GET UP TO SPEED WITH CONTENT SHARED AT THE EVENT</h3>
              <div id="button-row" class="slide flex w-20 h-30">
                <v-btn class="w-40 m-3 ml-1" id="buttons">
                  <span class="textCards"
                    >KEY TAKEAWAYS FROM THE GAMES CX GUILD 2020<br
                  /></span>
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/Games CX Guild_Virtual Event_Key Takeaways_Dec_2020.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
                <v-btn class="m-3 mr-1 pb-5" id="buttons">
                  <span class="textCards"
                    >CREATING ENHANCED PX IN STUDIO CULTURE <br />
                    <span class="textCardsDesc"
                      >Pascal Debroek, PX Hub</span
                    ></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/Pascal Debroek_Creating Enhanced Player Experience in Studio Culture_Games CX Guild_December 3_2020.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
              </div>
              <div id="button-row" class="slide flex w-20 h-30">
                <v-btn class="m-3 ml-1 pb-5" id="buttons">
                  <span class="textCards"
                    >PANDEMIC: THE IMPACT ON THE CX WORLD <br />
                    <span class="textCardsDesc"
                      >Elena Loucaidou, Wargaming</span
                    ></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/Elena Loucaidou_Pandemic the impact on the CX world_Gamex CX Guild_December 3_2020.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
                <v-btn class="m-3 mr-1 pb-5" id="buttons">
                  <span class="textCards"
                    >WHAT DO PLAYERS EXPECT FROM CX?<br />
                    <span class="textCardsDesc"
                      >Catia Silva, Teleperformance CX Lab</span
                    ></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/Catia Silva_What do players expect from CX_Global CX Survey_Games CX Guild_December 3_2020.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PastEvents2020",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";

section {
  height: 20vh;
}

#linkEvent {
  text-align: left !important;
  font-family: "AlegreyaSans-BlackItalic";
  color: #ff5c00;
  border: white;
  font-size: 15px;
  text-align: left;
  padding: 0;
  -webkit-text-fill-color: #ff5c00;
}

// .downloadIcon {
//   max-width: 50px;
//   margin-left: 15px
// }

.hackathon {
  position: relative;
  height: 40vh;
  margin-top: 40px;
  margin-bottom: 40px;

  h2 {
    font-size: 4rem;
    font-family: "AlegreyaSans-BlackItalic" !important;
    background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h3,
  p {
    color: #404040;
    text-align: left;
  }

  h3 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
  }

  h4 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
  }

  h5,
  a {
    color: #ff5c00;
    text-decoration: none;
  }

  p {
    width: 80%;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .slide.flex.w-20.h-30 {
    padding-top: 20px;
    padding-bottom: 2px;
  }

  #linkEvent a {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ff5c00;
    border: white;
    font-size: 15px;
    text-align: left;
    padding: 0;
    -webkit-text-fill-color: #ff5c00;
  }

  #button-row {
    padding: 0px;
  }

  #button-row button {
    font-family: "AlegreyaSans-BlackItalic";
  }

  .downloadIcon {
    max-width: 50px;
    margin-left: 15px;
  }

  .download {
    max-width: 50px;
    position: absolute;
    right: 5%;
  }

  .textCards {
    position: absolute;
    left: 1%;
    font-size: 18px;
  }

  .textCardsDesc {
    position: absolute;
    color: #000;
    left: 0% !important;
    padding-bottom: 10px;
  }

  .theme--light.v-btn.v-btn--has-bg {
    color: #bc40e8;
    background-color: white;
    border-radius: 2% !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    width: 48%;
    height: 10vh;
    font-size: 14px;
  }

  .nome {
    font-family: WorkSans-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }

  &-slider {
    flex-wrap: nowrap;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  #br-tag {
    display: none;
  }

  .theme--light.v-btn:hover::before {
    opacity: 0;
  }

  .slide {
    flex: 0 0 auto;
    transition: all 500ms ease-out 0s;
    -webkit-transition: all 500ms ease-out 0s;
    overflow-x: hidden;
    padding: 0 1vw;
    // padding-top: 5vh;

    .team {
      height: 80%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .ellipse {
      position: absolute;
      bottom: 5%;
      right: -4%;
      height: 110%;
    }

    &.last {
      .nome {
        font-family: WorkSans-SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        // line-height: 70px; // 389%
      }

      .team {
        height: 80%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .ellipse {
        position: absolute;
        bottom: 5%;
        left: -4%;
        height: 110%;
      }
    }
  }

  .slide-control {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
  }
}

/*VIDEO*/

@media only screen and (max-width: 1780px) {
  #second-session {
    min-height: 50vh !important;
  }
}

video {
  margin-right: 20px;
}

.premio-inovacao {
  z-index: 1;
  background-color: transparent;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1660px) {
  h2 {
    font-size: 3rem !important;
  }

  h3 {
    font-size: 26px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    font-size: 14px !important;
  }

  .textCards {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1400px) {
  video {
    margin-right: 0px !important;
  }

  p {
    font-size: 12px !important;
  }
  .textCards {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1260px) {
  h2 {
    font-size: 2rem !important;
  }

  p {
    font-size: 12px !important;
  }
  .textCards {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1160px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  .textCards {
    left: 5% !important;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  .textCards {
    font-size: 18px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 50px;
  }

  .download {
    max-width: 50px !important;
    margin-left: 15% !important;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  h2 {
    font-size: 3.5rem !important;
  }

  h3 {
    font-size: 33px !important;
  }

  h4 {
    font-size: 25px !important;
  }
  .textCards {
    font-size: 14px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 30px;
    margin-top: 7px;
  }
  .download {
    max-width: 50px !important;
    margin-left: 15%;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 720px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  video {
    height: 150px;
  }

  .textCards {
    font-size: 12px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    margin-left: 10px !important;
  }

  .download {
    max-width: 30px !important;
    // margin-left: 15%;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 500px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  .textCards {
    font-size: 9px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    // max-width: 30px;
  }

  .download {
    position: absolute;
    left: 25%;
    // top: 1.5%;
    margin-top: 6%;
    right: 15%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}
</style>
