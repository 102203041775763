<template>
  <div id="mission">
    <accordion>
      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            JUNE 2021 EDITION<br />
            <h5 id="subtext">
              <br />SOLVING THE SELF-SERVICE EQUATION: ONLINE MEETING
            </h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" this.visible!="false" id="content">
          <PastEvents2021 />
        </template>
      </accordion-item>

      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            DECEMBER 2020 EDITION<br />
            <h5 id="subtext"><br />FIRST EVER VIRTUAL GATHERING</h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <PastEvents2020 />
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>

<script>
import Accordion from "../template/Accordion.vue";
import AccordionItem from "../template/AccordionItem";
import PastEvents2021 from "../components/PastEvents2021";
import PastEvents2020 from "../components/PastEvents2020";

export default {
  name: "PastEvents",
  components: {
    Accordion,
    AccordionItem,
    PastEvents2021,
    PastEvents2020,
  },
  props: ["layoutChange"],
  async created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);

accordion-item {
  padding-left: 0 !important;
}

template {
  padding-left: 0 !important;
  font-family: "WorkSans-Regular" !important;
}

h1 {
  font-family: "AlegreyaSans-BlackItalic" !important;
  -webkit-text-fill-color: darkorchid;
  font-size: 4rem;
}

h5 {
  font-family: "AlegreyaSans-BlackItalic" !important;
}

#id {
  padding-left: 50vw;
}

span {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px !important;
  font-family: "WorkSans-Regular" !important;
  font-weight: 400 !important;
}

ul {
  /* display:flex; */
  margin-bottom: 10px;
  font-size: 14px !important;
  font-family: "WorkSans-Regular" !important;
  font-weight: 400 !important;
}

#subtext {
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#mission {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  margin-top: 60px;
}

.accordion li {
  padding: 20px 15vw;
}

.accordionBg {
  height: 10px;
  width: auto;
  margin-top: 3%;
}

@media only screen and (max-width: 800px) {
  h1 {
    color: #7e08a8 !important;
    background: none !important;
    -webkit-text-fill-color: none !important;
    font-size: 36px !important;
  }
}
</style>
