import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import { BootstrapVue, BootstrapVueIcons  } from "bootstrap-vue";
import 'vuetify/dist/vuetify.min.css';
import router from "./router";
import store from "./store";
var VueScrollTo = require('vue-scrollto');
import VModal from 'vue-js-modal'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueScrollTo)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMeta);
Vue.use(VModal)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
