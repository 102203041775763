<template>
  <div class="login">
    <img id="logo" src="@/assets/guild/LOGO_GAMESCXGUILD.png" alt="" />
    <div class="bg-video-full">
      <video autoplay muted loop class="bg-video" id="bg-video-home">
        <source
          src="https://cxgamesguild.com/assets/BG_Intro.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <section class="hackathon">
      <div class="hackathon-slider w-100 h-40">
        <div class="slide w-20 h-30">
          <div class="w-30">
            <div class="w-100">
              <h1>WELCOME TO THE GAMES CX GUILD PLATFORM!</h1>
              <p class="intro">
                This access is exclusive to Guild members only. Fill out your
                credentials to enter.
              </p>
            </div>
            <div class="form-login">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  class="formInputs"
                  background-color="white"
                  color="rgb(255, 92, 0)"
                  outlined
                  v-model="name"
                  label="EMAIL"
                  required
                ></v-text-field>
                <v-text-field
                  class="formInputs"
                  background-color="#ffffff"
                  color="rgb(255, 92, 0)"
                  outlined
                  v-model="passwordData"
                  type="password"
                  label="UNIQUE ID NUMBER"
                  required
                ></v-text-field>
                <v-btn
                  color="#ff5c00"
                  block
                  :disabled="!valid"
                  class="mt-6 btn-login"
                  @click="loginForm(name, passwordData)"
                  @keyup.enter="loginForm(name, passwordData)"
                  >LET ME IN</v-btn
                >
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LayoutLogin",
  props: {
    title: String,
    description: String,
    password: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Boolean,
      default: false,
    },
    loginForm: {
      type: Function,
    },
  },
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail necessita ser válido",
      ],
      valid: true,
      passwordData: "",
      name: "",
      email: "",
    };
  },
  methods: {
    getPrevRoute() {
      window.history.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);

div {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
}
.bg-video-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  z-index: -100;
}
#bg-video-home {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#logo {
  position: absolute;
  z-index: -1;
  top: 40px;
  left: 10vw;
  // left: 15vw;
  height: 125px;
}
h1 {
  justify-items: center;
  color: white;
  padding-top: 2vh;
  font-family: "AlegreyaSans-BlackItalic" !important;
  font-size: 4rem;
  font-weight: 900;
  line-height: 88px;
  width: 80%;
}
p.intro {
  font-family: "WorkSans-Regular" !important;
  font-size: 18px !important;
  width: 50% !important;
  padding-bottom: 60px;
  color: white;
  overflow-y: hidden;
}

.v-input {
  font-family: "AlegreyaSans-BlackItalic" !important;
  width: 40vw;
}
.form-login::placeholder {
  color: #e5e5e5;
}
section.hackathon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;
  overflow-y: hidden;
  max-height: 100%;
}

.theme--light.v-btn.v-btn--has-bg {
  font-family: "AlegreyaSans-BlackItalic" !important;
  color: #ffffff;
  height: 6vh;
  font-size: 20px;
}

.v-btn--block {
  min-width: 50% !important;
  margin: auto;
  border-radius: 0;
}

.formInputs {
  border-radius: 0;
}

.v-label.v-label--active.theme--light {
  font-size: 25px;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1400px) {
  h1 {
    padding-top: 10vh;
    font-size: 3rem !important;
    line-height: 40px !important;
    width: 90%;
  }

  section.hackathon {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 800px) {
  section.hackathon {
    min-height: 20vh;
    margin-top: 20%;
  }

  p.intro {
    font-family: "WorkSans-Regular" !important;
    font-size: 14px !important;
    width: 80% !important;
    padding-top: 20px;
    padding-bottom: 30px !important;
    color: white;
    overflow-y: hidden;
  }

  .v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    height: 10vh;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .v-input {
    font-family: "AlegreyaSans-BlackItalic" !important;
    width: 80vw;
    height: 10vh;
  }

  #logo {
    top: 20px;
    height: 75px;
  }

  // #bg-video-home {
  //   overflow: hidden;
  //   max-height:50%!important;
  //   height: 50vh!important;
  //   top:45%
  // }
}
</style>