<template>
  <div class="relative" @mouseover="hover = true" @mouseleave="hover = false">
    <section class="hackathon flex">
      <div class="container">
        <div v-for="(item, i) in items" :key="i">
          <div class="cartao">
            <img
              class="avatar"
              style="border-radius: 50% !important"
              :src="'https://cxgamesguild.com/members/' + item.imagem"
            />
            <div class="job">
              <h5>{{ item.nome }}</h5>
              <p class="empresa">{{ item.cargo }}</p>
              <p
                class="cargo mb-0"
                :style="cssEstado + item.cor + '!important'"
              >
                {{ item.estado }}
              </p>

              <a
                v-if="item.linkedin"
                class="pt-0 mt-0"
                style="font-size: 0"
                target="_blank"
                :href="item.linkedin"
                ><img id="linkedin" src="@/assets/guild/Linkedin.png" alt=""
              /></a>
              <a
                v-else-if="item"
                class="pt-0 mt-0"
                style="font-size: 0"
                target="_blank"
                :href="item"
                ><img
              /></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GuildMembers",
  data() {
    return {
      // hover: false,
      open: false,
      slide: 1,
      value: -1,
      items: [],
      cssEstado: "color: ",
    };
  },
  created: function () {
    let that = this;
    axios
      .post(
        "https://tp-meeting-guild.bydservices.pt/api/public/api/listAllUsers"
      )
      .then(function (response) {
        that.items = response.data.items;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);

p {
  color: black !important;
}

body {
  margin: 0;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  margin-top: 15vh;
  margin-bottom: 60px;
  max-width: 100%;
  padding: 0 15vw;
}

.cartao {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  align-content: left;
  margin-bottom: 25px;
}

.job {
  padding-left: 1em;
  max-width: 65%;
}
h5,
p {
  margin: 0;
}

.job h5 {
  font-family: "AlegreyaSans-BlackItalic" !important;
  text-transform: uppercase;
}

.job .empresa {
  text-transform: uppercase;
  color: #bdbdbd !important;
  margin-bottom: 0 !important;
}

.job .cargo {
  font-family: "WorkSans-Black";
  text-transform: uppercase;
}

#linkedin {
  height: 15px;
  cursor: pointer;
}

img .avatar {
  height: 110px !important;
  border-radius: 50% !important;
}

.container img {
  display: block;
  transition: all 100ms ease-out;
  display: inline;
}

.container img:hover {
  transform: scale(1.04);
}

.premio-inovacao {
  // background-image: url(./../assets/premio-inovacao/bg-premio.png);

  div {
    position: absolute;
    bottom: 20%;
  }

  h1.small {
    font-size: 25px;
  }
}

.premio {
  &-col {
    margin: 15% 0 0 5%;
  }

  p:not(.toggle-text) {
    color: black !important;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &-media img {
    width: 100%;
  }
}

.slide-control.flex.jc-sb.ai-c {
  bottom: 4vh;
}

.hackathon {
  position: relative;

  h3,
  p {
    color: white;
    text-align: left;
  }

  h3 {
    font-family: "Internacional-Black";
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
  }

  h4 {
    font-family: Verdana;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
    color: $green;
  }

  p {
    font-size: 14px;
    line-height: 159%;
    // margin-bottom: 10px;
  }

  .nome {
    font-family: Internacional-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }

  &-slider {
    flex-wrap: nowrap;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slide {
    flex: 0 0 auto;
    transition: all 500ms ease-out 0s;
    -webkit-transition: all 500ms ease-out 0s;
    overflow-x: hidden;
    padding: 30px;

    .team {
      height: 80%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .ellipse {
      position: absolute;
      bottom: 5%;
      right: -4%;
      height: 110%;
    }

    &.last {
      .nome {
        font-family: Internacional-SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        // line-height: 70px; // 389%
      }

      .team {
        height: 80%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .ellipse {
        position: absolute;
        bottom: 5%;
        left: -4%;
        height: 110%;
      }
    }
  }

  .slide-control {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
  }
}

/*VIDEO*/
.relative {
  position: relative;
}

.premio-inovacao {
  z-index: 1;
  background-color: transparent;
}

/*ANIMAÇÕES*/
.revealed h1 {
  animation: fadeInUp;
  animation-duration: 2s;
}

.revealed p {
  animation: fadeInUp;
  animation-duration: 2s;
}

/*REPONSIVE*/
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 50px;
  }

  video {
    height: 500px;
    padding-left: 10px;
  }

  #videoCard {
    margin-left: 0px;
  }

  .w-100 {
    width: 100%;
    flex-direction: column;
  }

  .avatar {
    width: 100px !important;
  }

  .premio.flex {
    display: block;
  }

  .ellipse,
  .team {
    display: none !important;
  }

  .hackathon .slide-control {
    bottom: 10%;
  }
  .container {
    padding: 0 5vw;
  }
}
</style>
