<template>
  <div class="relative">
    <section class="next-event">
      <div id="image">
        <a
          href="https://series.leaderinsights.co/playerexperience "
          target="_blank"
          ><img src="@/assets/guild/GAMESCXGUILD_BANNER-03.jpg" alt=""
        /></a>
        <!-- <a href=""><img src="@/assets/guild/test-image.jpeg" alt=""></a> -->
      </div>
      <div id="text">
        <h3>ON DEMAND</h3>
        <h1>STAYING IN THE GAME</h1>
        <h3 id="text-black" class="pb-5">LEADER INSIGHTS SERIES</h3>
        <h4>ONLINE EVENT</h4>
        <h4>WITH PASCAL DEBROEK</h4>
        <h4 class="mb-5">FOUNDER & CONTENT CREATOR AT THEPXHUB</h4>

        <p>
          The global games industry is changing radically. Production costs are
          rising, and the technology is racing ahead. To remain relevant,
          studios need to evolve their game, understand how the new business and
          consumer rules have impacted their operations, and how they can help
          create services that give the players what they want.
        </p>
        <p>
          Join us and Pascal Debroek, Founder & Content Creator @ThePXHub, to
          discover your path to advanced Player Experiences. Learn how to manage
          resources, technology priorities, and constraints, shortening the
          distance between understanding the assumptions behind Player
          Experience and operationalizing it at a time of increasing demand.
        </p>
        <!-- <v-btn class="mt-5"><a class="save" :href="'https://live.cxgamesguild.com/?m=live-guildevent&username=' + nameUser.split(' ')[0]" target="_blank"> JOIN THE MEETING!</a></v-btn>  -->
        <v-btn class="mt-5"
          ><a
            class="save"
            href="https://series.leaderinsights.co/playerexperience"
            target="_blank"
            >WATCH ON DEMAND</a
          ></v-btn
        >
        <br />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NextEvent",
  props: ["layoutChange"],
  components: {},
  async created() {},
  data() {
    return {};
  },
  computed: {
    nameUser() {
      return this.$store.state.userLogin.name;
    },
    imagemUser() {
      return this.$store.state.userLogin.imagem;
    },
  },
};
</script>

<style scoped lang="scss">
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);
@import "@/assets/styles/main.scss";
section {
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

img {
  max-height: 100%;
}

#image {
  max-width: 50%;
  margin: 5px;
  padding-left: 15vw;
  // height: 600px;
}

#image img {
  max-width: 80%;
}

#text {
  max-width: 50%;
  margin: auto;
  padding-right: 10vw;
}

#text-black {
  color: #404040;
}

.ics {
  font-size: 14px;
  text-align: center;
  padding: 5px 0px;
  justify-content: space-between;
  text-decoration: none !important;
  background: #ff5c00;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ff5c00;
}

.save {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}

.next-event {
  h3,
  p {
    color: black;
    text-align: left;
    width: 80%;
  }

  h1 {
    font-size: 4rem;
    font-family: "AlegreyaSans-BlackItalic" !important;
    background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-family: "AlegreyaSans-BlackItalic";
    color: #404040;
  }

  h3 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 54px;
    letter-spacing: 0.02em;
    // margin-bottom: 10px;
    color: #bdbdbd;
  }

  h4 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
  }

  h5 {
    color: #bdbdbd;
  }

  p {
    // width: 35%;
    font-size: 14px !important;
    font-family: "WorkSans-Regular" !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .theme--light.v-btn.v-btn--has-bg {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ffffff;
    background-color: #ff5c00;
    border-radius: 0% !important;
    width: 20vw;
    height: 5vh;
    font-size: 20px;
    margin-bottom: 15px;
  }

  button.btn.btn-secondary {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ff5c00;
    background-color: #ffffff;
    border: white;
    border-radius: 0% !important;
    width: 20vw;
    height: 5vh;
    font-size: 12px;
    margin-bottom: 15px;
    text-align: left;
    padding: 0 !important;
  }

  svg {
    color: black;
    margin-right: 20px;
    font-size: 15px !important;
  }

  .nome {
    font-family: WorkSans-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }
}

/*RESPONSIVE*/

@media only screen and (max-width: 1300px) {
  .mt-5.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    width: 60%;
  }
}

@media only screen and (max-width: 1060px) {
  section {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
  }

  p {
    width: 100% !important;
  }

  .mt-5.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    width: 100%;
    height: 10vh;
    font-size: 20px;
    margin-bottom: 15px;
  }

  button.btn.btn-secondary {
    width: 100% !important;
    font-size: 12px;
    margin-bottom: 15px;
    // margin-left: 15vw;
  }

  img {
    max-height: 120%;
  }

  #image {
    max-width: 120%;
    margin: 5px;
    padding-left: 12vw;
  }

  #image img {
    max-width: 86%;
    padding-bottom: 40px;
  }

  #text {
    padding-left: 3em;
    padding-right: 3em;
    max-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 36px !important;
  }

  h3 {
    font-size: 30px !important;
    line-height: 1.2 !important;
  }

  h4 {
    font-size: 20px !important;
  }
}
</style>
