<template>
  <main class="main-layout">
    <Navbar @logout="logout" />
    <Slides />
    <Navigation />
  </main>
</template>

<script>
import Navbar from "../components/Navbar";
import Slides from "../components/Slides";
import Navigation from "../components/Navigation";
// import SideBar from "../components/Sidebar";

export default {
  name: "LayoutDefault",
  components: {
    Navbar,
    Slides,
    Navigation,
    // SideBar,
  },
  data: () => ({
    overlayLogout: false,
  }),
  methods: {
    logout(cond) {
      if (!cond) {
        this.overlayLogout = true;
      } else {
        this.$store.dispatch("resetState");
        this.overlayLogout = false;
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss">
// @import "@/assets/base/_main.scss";
</style>
