<template>
  <div class="relative">
    <section class="hackathon second">
      <div class="hackathon-slider flex w-20 h-40">
        <div class="slide flex w-20 h-30">
          <div class="w-20 flex ai-c" id="first-session">
            <div class="w-20">
              <h2>GAMES CX GUILD</h2>
              <h3>2021 EDITION</h3>
              <h4>JUNE, 2021</h4>
              <p class="mb-5 mt-5">
                This time around, we have covered self-service tools and
                solutions that help improve the player experience. Although we
                still couldn’t be together physically, we’ve got connected
                amazing group of Games industry leaders from across the globe
                and brought together the best ideas for an insightful exchange.
              </p>
            </div>
            <div class="w-30">
              <video
                autoplay
                muted
                loop
                controls
                class="bg-video"
                width="520"
                height="auto"
              >
                <source
                  src="https://cxgamesguild.com/assets/Guild21_PostEvent_baixa.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hackathon second" style="min-height: 20px !important">
      <div class="hackathon-slider flex w-100">
        <div class="slide flex w-100">
          <div class="flex ai-c">
            <div class="w-100" id="second-session">
              <h3>GET UP TO SPEED WITH CONTENT SHARED AT THE EVENT</h3>
              <div id="button-row" class="slide flex w-20 h-30">
                <v-btn class="w-40 m-3 ml-1" id="buttons">
                  <span class="textCards"
                    >GAME CHANGERS: WHAT ARE THE GAMES CX TRENDS <br />
                    TO LOOK FOR ENHANCED PLAYER EXPERIENCE? <br />
                    <span class="textCardsDesc"
                      >GUILD 2021 WHITE PAPER
                    </span></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://www.cxgamesguild.com/docs/2021/Games_CX_GUILD_White_Paper_Game_Changers_Trends_2021.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
                <v-btn class="w-40 m-3 ml-1" id="buttons">
                  <span class="textCards"
                    >LEVERAGING SELF-SERVICE TO UNLOCK <br />
                    YOUR ORGANIZATION’S FULL POTENTIAL <br />
                    <span class="textCardsDesc"
                      >BOYD BEASLEY, BETHESDA
                    </span></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/2021/Boyd_Beasley_Bethesda_Leveraging_self-service_to_unlock_your_organization_full_potential.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
              </div>
              <div id="button-row" class="slide flex w-20 h-30">
                <v-btn class="m-3 ml-1 pb-5" id="buttons">
                  <span class="textCards"
                    >UBISOFT SELF HELP AND AUTOMATION STRATEGY <br />
                    <span class="textCardsDesc"
                      >CHRIS FOSTER, UBISOFT</span
                    ></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/2021/Chris-Foster_Ubisoft_Ubisoft_Self Help_and_Automation_Strategy.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
                <v-btn class="m-3 ml-1 pb-5" id="buttons">
                  <span class="textCards"
                    >GETTING THE HIGH SCORE IN PLAYER <br />
                    SUPPORT THROUGH AI <br />
                    <span class="textCardsDesc"
                      >DANNY KUIVENHOVEN, TELEPERFORMANCE</span
                    ></span
                  >
                  <a
                    class="download"
                    target="_blank"
                    href="https://cxgamesguild.com/docs/2021/Danny_Kuivenhoven_TP_Getting_the_High_Score_in_Player_Support_through_AI.pdf"
                    ><img
                      src="@/assets/guild/get_it.png"
                      class="downloadIcon pt-5"
                      alt=""
                  /></a>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PastEvents2021",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);

section {
  height: 20vh;
}

#linkEvent {
  text-align: left !important;
  font-family: "AlegreyaSans-BlackItalic";
  color: #ff5c00;
  border: white;
  font-size: 15px;
  text-align: left;
  padding: 0;
  -webkit-text-fill-color: #ff5c00;
}

h1 {
  font-size: 4rem;
  font-family: "AlegreyaSans-BlackItalic" !important;
  background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.hackathon {
  position: relative;
  height: 40vh;
  margin-top: 40px;
  margin-bottom: 40px;

  h2 {
    font-size: 4rem;
    font-family: "AlegreyaSans-BlackItalic" !important;
    background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h3,
  p {
    color: #404040;
    text-align: left;
  }

  h3 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
  }

  h4 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
  }

  h5,
  a {
    color: #ff5c00;
    text-decoration: none;
  }

  p {
    width: 80%;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .slide.flex.w-20.h-30 {
    padding-top: 20px;
    padding-bottom: 2px;
  }

  #linkEvent a {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ff5c00;
    border: white;
    font-size: 15px;
    text-align: left;
    padding: 0;
    -webkit-text-fill-color: #ff5c00;
  }

  #button-row {
    padding: 0px;
  }

  #button-row button {
    font-family: "AlegreyaSans-BlackItalic";
  }

  .downloadIcon {
    max-width: 50px;
    margin-left: 15px;
  }

  .download {
    max-width: 50px;
    position: absolute;
    right: 5%;
  }

  .textCards {
    position: absolute;
    left: 1%;
    font-size: 18px;
    text-align: left;
  }

  .textCardsDesc {
    position: absolute;
    color: #000;
    left: 0% !important;
    padding-bottom: 10px;
  }

  .theme--light.v-btn.v-btn--has-bg {
    color: #bc40e8;
    background-color: white;
    border-radius: 2% !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    width: 48%;
    height: 10vh;
    font-size: 14px;
    padding-bottom: 1%;
  }

  .nome {
    font-family: WorkSans-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }

  &-slider {
    flex-wrap: nowrap;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  #br-tag {
    display: none;
  }

  .theme--light.v-btn:hover::before {
    opacity: 0;
  }

  .slide {
    flex: 0 0 auto;
    transition: all 500ms ease-out 0s;
    -webkit-transition: all 500ms ease-out 0s;
    overflow-x: hidden;
    padding: 0 1vw;
    // padding-top: 5vh;

    .team {
      height: 80%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .ellipse {
      position: absolute;
      bottom: 5%;
      right: -4%;
      height: 110%;
    }

    &.last {
      .nome {
        font-family: WorkSans-SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        // line-height: 70px; // 389%
      }

      .team {
        height: 80%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .ellipse {
        position: absolute;
        bottom: 5%;
        left: -4%;
        height: 110%;
      }
    }
  }

  .slide-control {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
  }
}

/*VIDEO*/

@media only screen and (max-width: 1780px) {
  #second-session {
    min-height: 50vh !important;
  }
}

video {
  margin-right: 20px;
}

.premio-inovacao {
  z-index: 1;
  background-color: transparent;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1660px) {
  h2 {
    font-size: 3rem !important;
  }

  h3 {
    font-size: 26px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    font-size: 14px !important;
  }

  .textCards {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1400px) {
  video {
    margin-right: 0px !important;
  }

  p {
    font-size: 12px !important;
  }
  .textCards {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1260px) {
  h2 {
    font-size: 2rem !important;
  }

  p {
    font-size: 12px !important;
  }
  .textCards {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1160px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  .textCards {
    left: 5% !important;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  .textCards {
    font-size: 18px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 50px;
  }

  .download {
    max-width: 50px !important;
    margin-left: 15% !important;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  h2 {
    font-size: 3.5rem !important;
  }

  h3 {
    font-size: 33px !important;
  }

  h4 {
    font-size: 25px !important;
  }
  .textCards {
    font-size: 14px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 30px;
    margin-top: 7px;
  }

  .download {
    max-width: 50px !important;
    margin-left: 15%;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 720px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  video {
    height: 150px;
  }

  .textCards {
    font-size: 12px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  .hackathon .downloadIcon[data-v-9600c510] {
    max-width: 30px;
    margin-top: 10px;
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 30px;
  }

  .download {
    max-width: 30px !important;
    // margin-left: 15%;
    position: absolute;
    right: 5%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}

@media only screen and (max-width: 500px) {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px !important;
  }

  .hackathon {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    // padding-left: 3em;
    width: 100% !important;
    height: 100% !important;
  }

  .textCards {
    font-size: 9px !important;
    margin-bottom: 35px;
  }

  .hackathon .textCards[data-v-f65ecf0a] {
  }

  #linkEvent {
    padding-bottom: 40px;
  }

  #button-row,
  #buttons {
    width: 99%;
    max-height: 100%;
  }

  #buttons {
    margin-left: 0px !important;
    height: 12vh;
  }

  img {
    width: 100%;
    align-content: center !important;
    align-items: center;
    align-self: center;
    margin-left: 10%;
  }

  .downloadIcon {
    max-width: 30px;
  }

  .download {
    position: absolute;
    left: 25%;
    // top: 1.5%;
    margin-top: 8%;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0px !important;
  }
}
</style>
