<template>
  <div id="container">
    <div id="contacts">
      <div id="social">
        <p v-if="$route.path.includes('/login')">{{ accessLogin }}</p>
        <p id="p-margin" v-if="!$route.path.includes('/login')">{{ access }}</p>
        <b-button-group>
          <b-button
            id="button1"
            class="border rounded-pill p-1"
            variant="outline-primary"
          >
            <a href="https://www.linkedin.com/in/garrettkenny/" target="_blank"
              ><b-icon icon="person-fill"></b-icon> Garrett</a
            >
          </b-button>
          <b-button
            id="button2"
            class="border rounded-pill p-1"
            variant="outline-primary"
          >
            <a
              href="https://www.linkedin.com/in/stefanabadzhiev/"
              target="_blank"
              ><b-icon icon="person-fill"></b-icon> Stefan</a
            >
          </b-button>
        </b-button-group>
      </div>
      <div id="title" style="text-align: right">
        <p>{{ title }}</p>
        <!-- <p>Need help? Contact us: marketing@emea.teleperformance.com</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      title: "© GAMES CX GUILD",
      accessLogin:
        "Don’t have access? Contact us to find out how to join the community.",
      access: "Questions? Don’t hesitate to reach out to us:",
    };
  },
};
</script>

<style lang="scss">
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);

p {
  margin-bottom: 0.5rem !important;
}

#container {
  display: flex;
  height: auto;
  min-height: 10vh;
  justify-content: center;
  justify-items: center;
  align-items: flex-end;
  width: 100vw;
  color: white;
  background-color: #404040;
  flex-direction: column;
  font-style: normal !important;
  padding: 2vh 15vw;
  z-index: 999;
  margin-top: 5vh;
}
#social {
  width: 100%;
  font-style: normal !important;
}
#contacts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
#title {
  text-align: left;
  min-width: 10vw;
}

#button2 {
  margin-left: 20px;
  min-width: 150px;
}

#button1 {
  min-width: 150px;
}

#button1 a,
#button2 a {
  color: #fff !important;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

button.btn.border.rounded-pill.p-2.btn-outline-primary {
  color: white;
  padding-right: 5px;
}

@media only screen and (max-width: 800px) {
  #container {
    height: auto;
    padding: 5vh 3vw;
    margin-top: 10vh;
  }

  p {
    font-size: 14px !important;
  }

  .btn-group {
    margin-right: 50px;
  }

  #p-margin {
    margin-left: 1em;
  }

  #button1,
  #button2 {
    transform: scale(0.8);
    padding-left: 1vw !important;
  }

  #button1 {
    min-width: 100px;
  }

  #button2 {
    min-width: 100px;
  }
}
</style>