<template>
  <div class="relative">
    <section>
      <div>
        <div class="next-event" id="header">
          <h1>GAMES CX GUILD<br />MEMBERSHIP BOX</h1>
          <br />
          <h3>
            WE WERE EXCITED TO GET TOGETHER AGAIN, GUILD MEMBERS! TO HELP THE
            WAIT FOR THE NEXT CATCH-UP SHORTER, WE’VE PREPARED SOMETHING FOR
            YOU.
          </h3>
          <p>
            Fill out the form, and we’ll deliver the Games CX Guild membership
            box to your place.
          </p>
        </div>
        <div class="row">
          <div class="graphics" id="image">
            <img
              src="@/assets/guild/GAMESCXGUILD_Package_07092021.png"
              alt=""
            />
            <br />
            <h4 class="productTitle">
              Guild membership box includes essentials for long virtual
              meetings:
            </h4>
            <ul>
              <li>Thermos for hot & cold drinks</li>
              <li>Notebook for key takeaways</li>
              <li>Case to keep necessary cables and chargers ready at hand</li>
              <li>T-shirt to look awesome ;)</li>
            </ul>
          </div>
          <modal name="my-first-modal"> This is my first modal </modal>
          <div class="row form">
            <MembershipBoxForm />
          </div>
        </div>
      </div>
      <div id="text"></div>
    </section>
  </div>
</template>

<script>
import MembershipBoxForm from "./MembershipBoxForm.vue";
export default {
  name: "MembershipBox",
  props: ["layoutChange"],
  components: {
    MembershipBoxForm,
  },
  async created() {},
  data() {
    return {};
  },
  computed: {
    nameUser() {
      return this.$store.state.userLogin.name;
    },
    imagemUser() {
      return this.$store.state.userLogin.imagem;
    },
  },
};
</script>

<style scoped lang="scss">
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);
@import "@/assets/styles/main.scss";

section {
  font-family: "WorkSans-Regular" !important;
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

img {
  max-height: 100%;
}

#header {
  padding: 20px 15vw;
}

#image {
  max-width: 40%;
  margin: 5px;
  padding-left: 15vw;
  // height: 600px;
}

#image img {
  max-width: 100%;
  transition: transform 0.2s; /* Animation */
}

#text {
  max-width: 50%;
  min-width: 30%;
  margin: auto;
  padding-right: 5vw;
}

#text-black {
  color: #404040;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 15%;
  align-content: stretch;
}

.graphics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ics {
  font-size: 14px;
  text-align: center;
  padding: 5px 0px;
  justify-content: space-between;
  text-decoration: none !important;
  background: #ff5c00;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ff5c00;
}

.save {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}

.next-event {
  h3 {
    color: black !important;
    line-height: 40px !important;
  }
  p {
    color: black !important;
    text-align: left;
    width: 100%;
    font-size: 20px !important;
  }

  h1 {
    font-size: 4rem;
    font-family: "AlegreyaSans-BlackItalic" !important;
    background: linear-gradient(269.42deg, #7e08a8 28.43%, #bc40e8 99.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-family: "AlegreyaSans-BlackItalic";
    color: #404040;
  }

  h3 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 54px;
    letter-spacing: 0.02em;
    // margin-bottom: 10px;
    color: #bdbdbd;
  }

  h4 {
    font-family: "AlegreyaSans-BlackItalic";
    font-style: normal;
    font-size: 25px;
    line-height: 26px;
  }

  h5 {
    color: #bdbdbd;
  }

  p {
    // width: 35%;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    padding-bottom: 35px;
    margin-bottom: 20px;
  }

  .theme--light.v-btn.v-btn--has-bg {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ffffff;
    background-color: #ff5c00;
    border-radius: 0% !important;
    width: 20vw;
    height: 5vh;
    font-size: 20px;
    margin-bottom: 15px;
  }

  button.btn.btn-secondary {
    font-family: "AlegreyaSans-BlackItalic";
    color: #ff5c00;
    background-color: #ffffff;
    border: white;
    border-radius: 0% !important;
    width: 20vw;
    height: 5vh;
    font-size: 12px;
    margin-bottom: 15px;
    text-align: left;
    padding: 0 !important;
  }

  svg {
    color: black;
    margin-right: 20px;
    font-size: 15px !important;
  }

  .nome {
    font-family: WorkSans-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }
}

/*RESPONSIVE*/

@media only screen and (max-width: 1500px) {
  .mt-5.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    width: 60%;
  }
}

@media only screen and (max-width: 1100px) {
  section {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
  }

  h1 {
    font-size: 36px !important;
  }

  h3 {
    font-size: 24px !important;
    line-height: 1.2 !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    width: 100% !important;
  }

  .mt-5.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    width: 100%;
    height: 10vh;
    font-size: 20px;
    margin-bottom: 15px;
  }

  button.btn.btn-secondary {
    width: 100% !important;
    font-size: 12px;
    margin-bottom: 15px;
    // margin-left: 15vw;
  }

  img {
    max-height: 120%;
  }

  .form {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  #image {
    max-width: 86%;
    padding-bottom: 40px;
    display: block;
    margin-left: 0% !important;
    transform: scale(0.9);
  }

  #text {
    padding-left: 3em;
    padding-right: 3em;
    max-width: 100%;
    min-width: 90%;
    margin: auto;
    margin-top: 50px;
  }
}

.productTitle {
  margin-top: 20px;
}
</style>
