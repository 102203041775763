<template>
  <div id="mission">
    <accordion>
      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            ABOUT GAMES CX GUILD<br />
            <h5 id="subtext"><br />COMMUNITY BACKGROUND AND STORY</h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <span
            >The Games CX Guild is an invitation-only group of games executives,
            thought leaders, and experts from across the globe, gathered to
            improve the Player Experience (PX) collectively. The Games CX Guild
            community has been reassembling regularly in various European
            locations for over five years, with the introduction of Asia’s Games
            CX Guild community in 2019. In 2020, the meeting went virtual for
            the first time, advocating the same spirit, goals, and commitments,
            with global leaders coming together to discuss and build the future
            of the Games industry.</span
          >
        </template>
      </accordion-item>

      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            OUR MISSION <br />
            <h5 id="subtext"><br />WHAT THE GUILD STANDS FOR</h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <span
            >We want the Games CX Guild to be a community where Games industry
            leaders work together to challenge and inspire each other within a
            safe, collaborative, respectful, and friendly culture to create an
            unforgettable player experience that gamers worldwide cherish.</span
          >
        </template>
      </accordion-item>

      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            OUR VISION<br />
            <h5 id="subtext"><br />OUR CURRENT FUTURE AND OBJECTIVES</h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <p>
            Our vision is to be the leading games community focused on Player
            Experience in the World by providing a platform where leaders can
            get together, learn together, and grow together.
          </p>
          <p>
            Teamwork, Transparency and Respect are the values which the Games CX
            GUILD puts into action in every single interaction with the
            community and its members.
          </p>
        </template>
      </accordion-item>

      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            OUR GOALS <br />
            <h5 id="subtext"><br />THE PURPOSE OF THIS COMMUNITY</h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <p>We have three main goals driving our efforts and members:</p>
          <p>
            1. We want to inspire leaders in the Games industry with stories and
            insights that create an unforgettable player experience for gamers
            to cherish.
          </p>
          <p>
            2. We are experienced builders, and we want to share our knowledge
            and lead by example with how we treat the experience of our gamers,
            fans, and friends in the Games industry.
          </p>
          <p>
            3. We want our community to calibrate their work honestly and openly
            among themselves for a broad appeal and the commercial success of
            all members and studios involved.
          </p>
        </template>
      </accordion-item>

      <accordion-item>
        <template slot="accordion-trigger">
          <h1>
            GUILD CODE<br />
            <h5 id="subtext">
              <br />THREE SIMPLE RULES EVERY GUILD MEMBER NEEDS TO FOLLOW
            </h5>
          </h1>
          <img class="accordionBg" src="@/assets/guild/arrow-down.png" alt="" />
        </template>
        <template slot="accordion-content" id="content">
          <p class="mb-5">
            We value your membership in this community, and we aim to keep it
            secure so you can discuss challenges and exchange insights freely
            with industry peers. In the spirit of healthy discourse, we kindly
            remind you to respect simple community rules.
          </p>

          <p><b>What is discussed in the Guild stays in the Guild.</b></p>
          <p class="mb-5">
            As Guild members, we discuss things close to our hearts — our jobs,
            communities, employees, and games. It is crucial, therefore, that we
            be able to speak our minds. That said, please keep anything that has
            been shared during and after the event within the Guild.
          </p>

          <p><b>Keynotes</b></p>
          <p class="mb-5">
            The presentations at the event, which you can access on this
            platform, should be kept within the Guild as well. The speakers have
            permitted us to make the keynotes available to all members. So
            please, extend the same courtesy by not sharing them publicly.
          </p>

          <p><b>Sharing on Public Channels</b></p>
          <p class="mb-5">
            We are happy to see you share on social media that you belong to
            this community and are attending the event. However, when sharing on
            any public channel, please refrain from mentioning any of the event
            content.
          </p>

          <p>Thank you for being a faithful Guild member!</p>
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>

<script>
import Accordion from "../template/Accordion.vue";
import AccordionItem from "../template/AccordionItem";

export default {
  name: "MissionVision",
  components: {
    Accordion,
    AccordionItem,
  },
  props: ["layoutChange"],
  async created() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=WorkSans);
@import url(//db.onlinewebfonts.com/c/32475be7e7241b887c54d2aee5876af0?family=AlegreyaSans);

accordion-item {
  padding-left: 0 !important;
}

template {
  padding-left: 0 !important;
  font-family: "WorkSans-Regular" !important;
}

h1 {
  font-family: "AlegreyaSans-BlackItalic" !important;
  -webkit-text-fill-color: darkorchid;
  font-size: 4rem;
}

h5 {
  font-family: "AlegreyaSans-BlackItalic" !important;
}

#id {
  padding-left: 50vw;
}

span {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px !important;
  font-family: "WorkSans-Regular" !important;
  font-weight: 400 !important;
}

ul {
  /* display:flex; */
  margin-bottom: 10px;
  font-size: 14px !important;
  font-family: "WorkSans-Regular" !important;
  font-weight: 400 !important;
}

#subtext {
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#mission {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  margin-top: 60px;
}

.accordion li {
  padding: 20px 15vw;
}

.accordionBg {
  height: 10px;
  width: auto;
  margin-top: 3%;
}

@media only screen and (max-width: 800px) {
  h1 {
    color: #7e08a8 !important;
    background: none !important;
    -webkit-text-fill-color: none !important;
    font-size: 36px !important;
  }
}
</style>
